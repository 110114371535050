






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Masonry extends Vue {
  @Prop({ default: 3 })
  private readonly columns!: number;

  @Prop({ default: '300px' })
  private readonly width!: string;

  @Prop({ default: 'var(--gutter)' })
  private readonly gap!: string;
}
